<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card class="cardClassDetailForm" title="LBLBASEINFO">
          <template slot="card-button">
            <q-btn-group outline >
              <c-appr-btn 
                ref="appr-btn"
                name="appr-btn"
                :editable="editable"
                :approvalInfo="approvalInfo"
                @beforeApprAction="approvalHealtyTarget"
                @callbackApprAction="approvalHealtyTargetCallback"
                @requestAfterAction="getDetail"
              />
              <c-btn 
                v-show="editable&&isResultWriting&&isOld" 
                :isSubmit="isComplete"
                :url="completeUrl"
                :param="target"
                mappingType="PUT"
                label="완료" 
                icon="check"
                @beforeAction="completeTarget"
                @btnCallback="completeTargetCallback" />
              <!--저장-->
              <c-btn 
                v-show="editable&&!disabled" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="target"
                :mappingType="mappingType"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveTarget"
                @btnCallback="saveTargetCallback" />
              <!--삭제-->  
              <c-btn 
                v-show="editable&&isOld&&!disabled&&!isResultWriting" 
                label="LBLREMOVE" 
                icon="delete"
                @btnClicked="deleteTarget" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-plant
                :required="true"
                :editable="editable"
                :disabled="disabled||isResultWriting"
                name="plantCd"
                v-model="target.plantCd">
              </c-plant>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
              <!--제목-->
              <c-text
                :required="true"
                :editable="editable"
                :disabled="disabled"
                label="제목"
                name="propelDeptName"
                v-model="target.propelDeptName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <c-field
                :editable="editable"
                :disabled="disabled"
                :data="target"
                deptValue="manageSupervisorDeptCd"
                type="dept_user"
                label="관리감독자"
                name="manageSupervisorId"
                v-model="target.manageSupervisorId"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--대상년도-->
              <c-datepicker
                :required="true"
                :editable="editable"
                :disabled="disabled||isResultWriting"
                type="year"
                default="today"
                label="LBL0010014"
                name="targetYear"
                v-model="target.targetYear"
              />
            </div>
            <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <!--작성부서-->
              <c-dept 
                :required="true" 
                :editable="editable" 
                :disabled="disabled||isResultWriting"
                type="edit" 
                label="LBLWRITEDEPT" 
                name="writeDeptCd" 
                v-model="target.writeDeptCd" />
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="안전보건 목표 및 추진계획 점검내용"
          :merge="grid.merge"
          :columns="grid.columns"
          :data="target.plans"
          :gridHeight="gridHeight"
          :editable="editable&&!disabled&&!isResultWriting"
          :usePaging="false"
          :isExcelDown="true"
          :filtering="false"
          :isFullScreen="true"
          :columnSetting="false"
          selection="multiple"
          rowKey="saiSafetyHealthyTargetPlanId"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable&&!disabled&&!isResultWriting" label="LBLADD" icon="add" @btnClicked="addTargetPlan"/>
              <c-btn v-if="editable&&!disabled&&!isResultWriting" label="LBLEXCEPT" icon="remove" @btnClicked="deleteTargetPlan" />
            </q-btn-group>
          </template>
          <template v-slot:customHeader="{ props, col }">
            <template v-if="!isPlanWriting">
              <q-btn-group outline>
                <q-btn
                  outline 
                  size="10px"
                  :color="col.btnColor" 
                  :label="col.icon" 
                  @click="openTargetMonth(col.month, col.saiSafetyHealthyTargetMonthId)">
                </q-btn>
              </q-btn-group>
            </template>
          </template>
        </c-table>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </div>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safety-healthy-target-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiSafetyHealthyTargetId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      target: {
        saiSafetyHealthyTargetId: '',  // 안전보건목표 일련번호
        plantCd: null,  // 사업장
        propelDeptName: '',  // 제목
        targetYear: '',  // 대상년도
        saiSafetyHealthyTargetStepCd: '',  // 대상년도
        manageSupervisorId: '',  // 대상년도
        writeDeptCd: '',  // 작성부서
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        plans: [],
        deletePlans: [],
        months: [],
      },
      grid: {
        merge: [{ index: 0, colName: "targetName" }],
        columns: [],
        data: [],
      },
      planRow: null,
      holidaies: [],
      editable: false,
      isSave: false,
      isComplete: false,
      isApproval: false,
      detailUrl: '',
      holidayUrl: '',
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      approvalUrl: '',
      requestUrl: '',
      mappingType: 'PUT',
      popupOptions: {
        isFull: false,
        width: '50%',
        target: null,
        title: '',
        visible: false,
        top: '50px',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = (this.contentHeight - 180);
      if (height < 500) {
        height = 500;
      }
      return String(height) + 'px';
    },
    isOld() {
      return Boolean(this.popupParam.saiSafetyHealthyTargetId)
    },
    disabled() {
      return this.target.saiSafetyHealthyTargetStepCd === 'SSHT000003'
        // 결재중인 경우 비활성화
        || this.target.approvalStatusCd === 'ASC0000001'
    },
    isResultWriting() {
      return this.target.saiSafetyHealthyTargetStepCd === 'SSHT000002'
    },
    isPlanWriting() {
      return !this.isOld || this.target.saiSafetyHealthyTargetStepCd === 'SSHT000001'
    },
    approvalInfo() {
      return {
        sysApprovalRequestId: this.target.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.target.approvalStatusCd, 
        apprEditable: this.isOld 
          && this.target.saiSafetyHealthyTargetStepCd === 'SSHT000001',
        param: this.target, // 결재 param
        approvalUrl: this.approvalUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        approvalTypeCd: 'APTC000022', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          saiSafetyHealthyTargetId: this.target.saiSafetyHealthyTargetId,
          isApprContent: true
        },
        approvalRequestName: this.target.propelDeptName, // 결재요청명 (문서 title)
        approvalConnId: this.target.saiSafetyHealthyTargetId, // 결재연결용 업무일련번호 (문서 key)
      }
    }
    // [E] 결재관련 버튼 컨트롤
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.safetyHealthy.get.url;
      this.holidayUrl = selectConfig.sys.holiday.list.url;
      this.saveUrl = transactionConfig.sai.safetyHealthy.insert.url;
      this.completeUrl = transactionConfig.sai.safetyHealthy.complete.url;
      this.deleteUrl = transactionConfig.sai.safetyHealthy.delete.url;
      this.approvalUrl = transactionConfig.sai.safetyHealthy.update.url;
      this.requestUrl = transactionConfig.sai.safetyHealthy.month.request.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiSafetyHealthyTargetId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.target, _result.data)

          this.getHolidaies();
          this.setGridHeader();

          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // 신규등록 
        this.$set(this.target, 'writeDeptCd', this.$store.getters.user.deptCd)
        this.setGridHeader();
      }
    },
    getHolidaies() {
      this.$http.url = this.holidayUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        year: this.target.targetYear
      };
      this.$http.request((_result) => {
        this.holidaies = _result.data
      },);
    },
    setGridHeader() {
      let isMonthData = this.target.months && this.target.months.length > 0;
      let scheduleChild = [];
      for (let idx = 1; idx <= 12; idx++) {
        let month = isMonthData && this.isOld ? this.$_.find(this.target.months, { yearmonth: `${this.target.targetYear}-${this.$_.padStart(idx, 2, '0')}` }): null
        let icon = '';
        let btnColor = '';
        if (month && month.saiSafetyHealthyTargetMonthId) {
          if (month.saiSafetyHealthyTargetMonthStepCd === 'SSHTM00001') {
            // 요청보낸 경우
            icon = '확인'; // 확인
            btnColor = 'blue';
          } else if (month.saiSafetyHealthyTargetMonthStepCd === 'SSHTM00002') {
            // 완료된 경우
            icon = '완료'; // 완료
            btnColor = 'green';
          }
        } else {
          // 요청하지 않은 경우
          icon = '요청'; // 요청
          btnColor = 'red';
        }
        scheduleChild.push({
          name: `month${idx}`,
          field: `month${idx}`,
          label: `${idx}월`,
          align: 'center',
          style: 'width:40px',
          sortable: false,
          headerCustom: true,
          type: 'check',
          'true': 'Y',
          'false': 'N',
          color: 'blue', 
          month: idx,
          saiSafetyHealthyTargetMonthId: month && month.saiSafetyHealthyTargetMonthId ? month.saiSafetyHealthyTargetMonthId : null,
          saiSafetyHealthyTargetMonthStepCd: month && month.saiSafetyHealthyTargetMonthStepCd ? month.saiSafetyHealthyTargetMonthStepCd : null,
          icon: icon,
          btnColor: btnColor,
        })
      }
      let schedule = {
        name: 'schedule',
        field: 'schedule',
        //추진일정
        label: 'LBL0010022',
        child: scheduleChild
      };
      this.grid.columns = [
        {
          name: 'targetName',
          field: 'targetName',
          label: '구분',
          align: 'center',
          style: 'width: 100px',
          sortable: false,
          fix: true,
        },
        {
          name: 'detailName',
          field: 'detailName',
          label: '점검내용',
          align: 'left',
          style: 'width: 200px',
          sortable: false,
          fix: true,
        },
        {
          name: 'deptName',
          field: 'deptName',
          label: '해당팀',
          align: 'center',
          style: 'width: 180px',
          sortable: false,
          type: 'deptMulti',
          deptCd: 'deptCd',
        },
        {
          name: 'inspectionCycle',
          field: 'inspectionCycle',
          label: '점검주기',
          align: 'center',
          style: 'width:100px',
          sortable: false,
        },
        {
          name: 'managerName',
          field: 'managerName',
          label: '담당자',
          align: 'left',
          style: 'width:150px',
          sortable: false,
        },
        {
          name: 'remark',
          field: 'remark',
          //비고
          label: 'LBLREMARK',
          align: 'left',
          style: 'width: 200px',
          sortable: false,
          type: 'textarea',
        },
        {
          name: 'relatedEvidence',
          field: 'relatedEvidence',
          label: '관련근거',
          align: 'left',
          style: 'width:250px',
          type: 'html',
          sortable: false
        },
      ];
      this.grid.columns.splice(5, 0, schedule);
    },
    addTargetPlan() {
      this.popupOptions.title = 'LBL0010041';   //안전보건 목표 및 세부추진사항 목록
      this.popupOptions.target = () => import(`${'./safetyHealthyMstPopup.vue'}`);
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeTargetPopup;
    },
    closeTargetPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let _idx = this.$_.findIndex(this.target.plans, { saiSafetyHealthyDetailId: item.saiSafetyHealthyDetailId })
          // _idx = _idx > -1 ? _idx : 0;
          if (_idx === -1) {
            this.target.plans.push({
              saiSafetyHealthyTargetPlanId: uid(),  // 안전보건목표 계획 일련번호
              saiSafetyHealthyTargetId: this.popupParam.saiSafetyHealthyTargetId,  // 안전보건목표 일련번호
              saiSafetyHealthyDetailId: item.saiSafetyHealthyDetailId,  // 안전보건목표 세부추진사항 일련번호
              saiSafetyHealthyId: item.saiSafetyHealthyId,  // 안전보건목표 마스터 일련번호
              targetName: item.targetName,  // 안전보건목표
              detailName: item.detailName,  // 세부추진사항
              deptCd: item.deptCd,  // 해당부서
              deptName: item.deptName,  // 해당부서
              inspectionCycle: item.inspectionCycle,
              relatedEvidence: item.relatedEvidence,
              managerName: item.managerName,
              remark: '',  // 비고
              month1: 'Y',  // 1월 계획
              month2: 'Y',  // 2월 계획
              month3: 'Y',  // 3월 계획
              month4: 'Y',  // 4월 계획
              month5: 'Y',  // 5월 계획
              month6: 'Y',  // 6월 계획
              month7: 'Y',  // 7월 계획
              month8: 'Y',  // 8월 계획
              month9: 'Y',  // 9월 계획
              month10: 'Y',  // 10월 계획
              month11: 'Y',  // 11월 계획
              month12: 'Y',  // 12월 계획
              ehsFlag: item.ehsFlag,
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C'
            })
          }
        })
      }
    },
    deleteTargetPlan() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: 'MSGEXCEPT', // 제외하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            if (!this.target.deletePlans) this.target.deletePlans = [];
            this.$_.forEach(selectData, item => {
              if (item.editFlag !== 'C'
                && this.$_.findIndex(this.target.deletePlans, { saiSafetyHealthyTargetPlanId: item.saiSafetyHealthyTargetPlanId })) {
                  this.target.deletePlans.push(item)
              }
              this.target.plans = this.$_.reject(this.target.plans, item);
            })
            this.$refs['table'].$refs['compo-table'].clearSelection();
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    openResult(row) {
      if (!row) return;
      this.$set(this.$data, 'planRow', row)
      // '[' + row.detailName + '] 상세'
      // this.$comm.getLangLabel('LBL0010070', {s1: row.detailName})
      this.popupOptions.title = this.$comm.getLangLabel('LBL0010070', {s1: row.detailName});  
      this.popupOptions.param = {
        saiSafetyHealthyTargetId: row.saiSafetyHealthyTargetId,
        saiSafetyHealthyTargetPlanId: row.saiSafetyHealthyTargetPlanId,
      };
      this.popupOptions.target = () => import(`${'./safetyHealthyTargetDetailPopup.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeResultPopup;
    },
    closeResultPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (result && result.col1) {
        this.$set(this.planRow, 'results', result.col1)
      }
    },
    openTargetMonth(month, saiSafetyHealthyTargetMonthId) {
      let yearmonth = `${this.target.targetYear}-${this.$_.padStart(month, 2, '0')}`
      if (this.$_.findIndex(this.target.months, { yearmonth: yearmonth }) > -1) {
        // 데이터가 존재하는 경우
        this.popupOptions.title = `${month}월 실적`;  
        this.popupOptions.param = {
          saiSafetyHealthyTargetId: this.popupParam.saiSafetyHealthyTargetId,
          saiSafetyHealthyTargetMonthId: saiSafetyHealthyTargetMonthId,
          targetYear: this.target.targetYear,
          month: month,
          manageSupervisorId: this.target.manageSupervisorId,
          holidaies: this.holidaies,
        };
        this.popupOptions.target = () => import(`${'./safetyHealthyTargetMonth.vue'}`);
        this.popupOptions.width = '100%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeTargetMonthPopup;
      } else {
        // 해당팀들에게 실적 작성 요청 confirm
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',  // 확인
          message: '해당팀들에게 실적작성 요청 보내겠습니까?', // 해당팀들에게 실적작성 요청 보내겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.requestUrl;
            this.$http.type = 'POST';
            this.$http.param = {
              saiSafetyHealthyTargetId: this.target.saiSafetyHealthyTargetId,
              yearmonth: yearmonth,
              regUserId: this.$store.getters.user.userId
            };
            this.$http.request((_result) => {
              this.target.months.push({
                saiSafetyHealthyTargetMonthId: _result.data,
                saiSafetyHealthyTargetId: this.target.saiSafetyHealthyTargetId,
                yearmonth: yearmonth,
                saiSafetyHealthyTargetMonthStepCd: 'SSHTM00001',
              })
              this.setGridHeader();
              this.popupOptions.title = `${month}월 실적`;  
              this.popupOptions.param = {
                saiSafetyHealthyTargetId: this.popupParam.saiSafetyHealthyTargetId,
                saiSafetyHealthyTargetMonthId: _result.data,
                targetYear: this.target.targetYear,
                month: month,
                manageSupervisorId: this.target.manageSupervisorId,
                holidaies: this.holidaies,
              };
              this.popupOptions.target = () => import(`${'./safetyHealthyTargetMonth.vue'}`);
              this.popupOptions.width = '100%';
              this.popupOptions.visible = true;
              this.popupOptions.closeCallback = this.closeTargetMonthPopup;
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    closeTargetMonthPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getDetail();
    },
    saveTarget() {
      if (this.isOld) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.sai.safetyHealthy.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.sai.safetyHealthy.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: 'MSGSAVE', // 저장하시겠습니까?   // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.target.regUserId = this.$store.getters.user.userId
              this.target.chgUserId = this.$store.getters.user.userId
      
              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveTargetCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.saiSafetyHealthyTargetId = result.data
      this.getDetail();
    },
    completeTarget() {
      let filterSchedules = this.$_.find(this.grid.columns, { name: 'schedule' }).child
      if (this.$_.findIndex(filterSchedules, filterSchedule => {
        return filterSchedule.icon !== '완료'
      }) > -1) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '실적 처리가 완료되지 않은 월이 존재합니다.\n완료가 끝난 후 진행바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.target.chgUserId = this.$store.getters.user.userId
      
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeTargetCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.saiSafetyHealthyTargetId = result.data
      this.getDetail();
    },
    deleteTarget() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.saiSafetyHealthyTargetId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    approvalHealtyTarget() {
      /**
       * 유효성 체크
       *  1. 추진 점검내용 하나 이상 등록
       *  2. 해당팀 안들어간 점검내용 있을 시 `기타`로 빠진다는 메시지로 변경
       */
      let message = '결재요청 하기 전 입력된 값을 저장합니다.\n진행하시겠습니까?';
      if (!this.target.plans || this.target.plans.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '추진 점검내용을 하나 이상 입력하세요.', // 추진 점검내용을 하나 이상 입력하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      } 

      if (this.$_.findIndex(this.target.plans, plan => {
        return !plan.deptCd
      }) > -1) {
        message += '\n(※ 해당팀이 비어있는 점검내용은 기타로 빠집니다.)'
      } 

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isApproval = !this.isApproval
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    approvalHealtyTargetCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
  }
};
</script>